<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .header-intro-generel-box {
    .title-box {
      span {
      }
    }
  }

  .digital-marketplaces-ueber-uns {
    position: relative;

    .img-logo-overview {
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
      margin-top: 30px;

      img {
        max-width: 500px;
        margin: 50px auto 40px auto;
        display: block;

        @media (max-width: $width-sm) {
          max-width: 80%;
        }
      }
    }

    .intro-boxen {
      border: 0px solid red;

      .txt-box {
        h2 {
          margin-bottom: 20px;
        }

        padding: 0px 30px;
      }

      .img-box {
        height: 500px;
        background-size: cover;
        background-position: center center;
        max-width: 90%;
        margin: 20px auto;
      }
    }

    .start-outer {
      padding-bottom: 30px;
      padding-top: 30px;

      .img-box-start {
        width: 100%;
        height: 700px;
        background-size: cover;
        background-position: center center;

        @media (max-width: $width-lg) {
          margin: 0 auto 30px auto;
        }
      }
    }

    .tab-outer-marketplaces {
      padding-top: 50px;

      .tab-content {
        padding: 60px 20px;

        .tab-pane {
          padding-top: 0px;

          h3 {
            letter-spacing: 1px;
            margin-top: 40px;

            font-family: 'Helvetica Neue LT W05 96 Blk It';
            letter-spacing: $letter-spacing-std;
          }

          h4 {
            letter-spacing: $letter-spacing-std;
          }

          ul {
            li {
              letter-spacing: 1px;
              padding: 5px 0;
            }
          }

          img {
            margin: 0 auto;
          }

          p {
            margin-top: 30px;
          }
        }
      }
    }

    .btn-std-strategie {
      margin-top: 30px;
      text-align: left !important;
      margin: 30px 0 0 0;

      .icon-img {
        top: 0px;
      }
    }

    .brands-overview {
      .border-right-line {
        border-right: 1px solid #ccc;

        @media (max-width: $width-md) {
          border-right: 0;
        }
      }

      .border-bottom-line {
        border-bottom: 1px solid #ccc;

        @media (max-width: $width-md) {
          border-bottom: 0;
        }
      }

      .border-left-line {
        border-left: 1px solid #ccc;

        @media (max-width: $width-md) {
          border-left: 0;
        }
      }
    }

    .brands-overview-inner {
      display: block;
      //border:1px solid red;
      height: 300px;
      cursor: pointer;
      transition: $transition-std;

      @media (max-width: $width-lg) {
        height: 250px;
      }

      @media (max-width: $width-md) {
        height: 200px;
      }

      img,
      .title-text {
        display: block;
        margin: 0px auto;
        max-width: 250px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        transition: $transition-std;
      }

      .title-text {
        font-family: 'American Typewriter ITC W04 Md';
        font-size: 200%;
        text-align: center;
      }

      &:hover {
        transform: scale(0.85);

        img {
          opacity: 0.8;
        }
      }
    }

    .person-box {
      position: relative;
      margin-bottom: 50px;

      .txt-box {
        text-align: left;
        padding: 60px 25px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        .name {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          font-size: 200%;

          @media (max-width: $width-lg) {
            font-size: 150%;
          }

          @media (max-width: $width-sm) {
            font-size: 130%;
          }
        }

        .job {
          margin-top: 20px;
          margin-bottom: 20px;
          letter-spacing: 1px;
          padding-right: 40px;

          strong {
            display: block;
            font-weight: normal;
            font-family: 'Helvetica Neue LT W05 65 Medium';
          }

          span {
            display: block;
            font-size: 90%;
            line-height: 1.8;
          }
        }

        .zitat {
          font-family: 'American Typewriter ITC W04 Md';

          margin-bottom: 40px;
          font-size: 110%;

          @media (max-width: $width-sm) {
            font-size: 100%;
          }

          .zitat-icon {
            display: block;
          }
        }
      }

      .box-middle {
        border: 1px solid #878787;
      }

      .box-bottom {
        text-align: left;
        border: 1px solid #878787;
        border-top: 0;
        padding: 20px 25px;

        a {
          display: block;
          letter-spacing: 1px;
          font-size: 90%;
          position: relative;

          .icon-img {
            position: absolute;
            right: 0;
            top: 0;

            img {
              width: 30px;
              height: auto;
            }
          }

          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}
</style>

<template>
  <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/marketplaces-startbild.jpg')})` }" headerImageText="Styria Digital Marketplaces" />-->
  <SocialMediaIconBox />
  <div class="digital-marketplaces-ueber-uns">
    <div class="container-fluid img-logo-overview">
      <div class="row padding-tb-s">
        <div class="col-lg-12">
          <img src="@/assets/img/marken/brands/styria-marketplaces.svg" alt="Marketplaces" class="img-fluid" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row header-section-box justify-content-center align-self-center padding-t-s">
        <div class="col-lg-3 offset-lg-2 col-md-4 align-self-center">
          <div class="headline-section">{{ $t('General.marketplaces') }}</div>
        </div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center">
          <h1>{{ $t('marketplaces-ueber-uns.ueber-uns') }}</h1>
        </div>
      </div>
      <div class="row start-outer justify-content-center">
        <div class="col-lg-5">
          <div class="img-box-start" :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/ueber-uns-v3.jpg')})` }"></div>
        </div>

        <div class="col-lg-5 offset-lg-1 justify-content-center align-self-center">
          <p>{{ $t('marketplaces-ueber-uns.ueber-uns-txt-v1') }}</p>
        </div>
      </div>
      <!-- -->
      <div class="tab-outer tab-outer-marketplaces">
        <ul class="nav justify-content-center" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="team-tab"
              data-bs-toggle="pill"
              data-bs-target="#tab-team"
              type="button"
              role="tab"
              aria-controls="team"
              aria-selected="true"
            >
              {{ $t('marketplaces-ueber-uns.team-hl') }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="ceo-nachricht-tab"
              data-bs-toggle="pill"
              data-bs-target="#tab-ceo-nachricht"
              type="button"
              role="tab"
              aria-controls="ceo-nachricht"
              aria-selected="false"
            >
              {{ $t('marketplaces-ueber-uns.ceo-nachricht-hl') }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="tab-strategie-tab"
              data-bs-toggle="pill"
              data-bs-target="#tab-strategie"
              type="button"
              role="tab"
              aria-controls="tab-strategie"
              aria-selected="false"
            >
              {{ $t('marketplaces-ueber-uns.strategie-hl') }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="tab-brands-tab"
              data-bs-toggle="pill"
              data-bs-target="#tab-brands"
              type="button"
              role="tab"
              aria-controls="tab-brands"
              aria-selected="false"
            >
              {{ $t('marketplaces-ueber-uns.marken-hl') }}
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="tab-team" role="tabpanel" aria-labelledby="team-tab">
            <div class="row justify-content-center align-self-center">
              <div class="col-12">
                <h3 class="text-center padding-b-s">Marketplaces Holding-Team</h3>
              </div>
              <div class="row align-self-end justify-content-center">
                <div class="col-lg-4">
                  <div class="person-box">
                    <img src="@/assets/img/marketplaces/mike-berger.jpg" alt="Michael Berger" class="img-fluid" />
                    <div class="box-middle">
                      <div class="txt-box">
                        <div class="name">Mike Berger</div>
                        <div class="job">
                          <strong>CEO Styria Digital Marketplaces</strong>
                        </div>
                      </div>
                    </div>
                    <div class="box-bottom">
                      <a href="mailto:michael.berger@styria.com"
                        >michael.berger@styria.com<span class="icon-img"
                          ><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="person-box">
                    <img src="@/assets/img/marketplaces/robert-broermann.jpg" alt="Robert Broermann" class="img-fluid" />
                    <div class="box-middle">
                      <div class="txt-box">
                        <div class="name">Robert Broermann</div>
                        <div class="job">
                          <strong>Business Development</strong>
                        </div>
                      </div>
                    </div>
                    <div class="box-bottom">
                      <a href="mailto:robert.broermann@styria.com"
                        >robert.broermann@styria.com<span class="icon-img"
                          ><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-self-end justify-content-center">
                <div class="col-lg-4">
                  <div class="person-box">
                    <img src="@/assets/img/marketplaces/margit-holzapfel.jpg" alt="Margit Holzapfel" class="img-fluid" />
                    <div class="box-middle">
                      <div class="txt-box">
                        <div class="name">Margit Holzapfel</div>
                        <div class="job">
                          <strong>Performance Management</strong>
                        </div>
                      </div>
                    </div>
                    <div class="box-bottom">
                      <a href="mailto:margit.holzapfel@styria.com"
                        >margit.holzapfel@styria.com<span class="icon-img"
                          ><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="person-box">
                    <img src="@/assets/img/marketplaces/benjamin-zubovic.jpg" alt="Benjamin Zubovic" class="img-fluid" />
                    <div class="box-middle">
                      <div class="txt-box">
                        <div class="name">Benjamin Zubovic</div>
                        <div class="job">
                          <strong>Strategy &amp; Stratecig Projects</strong>
                        </div>
                      </div>
                    </div>
                    <div class="box-bottom">
                      <a href="mailto:benjamin.zubovic@styria.com"
                        >benjamin.zubovic@styria.com<span class="icon-img"
                          ><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
                      ></a>
                    </div>
                  </div>
                </div>
              </div>

              <!-- -->
            </div>
          </div>
          <div class="tab-pane fade" id="tab-ceo-nachricht" role="tabpanel" aria-labelledby="ceo-nachricht-tab">
            <div class="row justify-content-center align-self-center">
              <div class="col-lg-4 justify-content-center align-self-center">
                <img src="@/assets/img/marketplaces/mike-berger-ceo-nachricht.jpg" alt="Mike Berger" class="img-fluid shadow" />
              </div>
              <div class="col-lg-6 offset-lg-1 justify-content-center align-self-center">
                <h3>{{ $t('marketplaces-ueber-uns.ceo-nachricht-hl') }}</h3>
                <h4>Michael Berger</h4>
                <p v-html="$t('marketplaces-ueber-uns.ceo-nachricht-txt')"></p>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="tab-strategie" role="tabpanel" aria-labelledby="tab-strategie-tab">
            <div class="row justify-content-center align-self-center">
              <div class="col-lg-10 col-12">
                <h3 class="padding-b-s">{{ $t('marketplaces-ueber-uns.strategie-hl-v2') }}</h3>
              </div>
              <div class="col-lg-5 offset-lg-1 justify-content-center align-self-center order-lg-2">
                <img src="@/assets/img/marketplaces/strategie-ueber-uns.jpg" alt="test" class="img-fluid shadow" />
              </div>
              <div class="col-lg-6 justify-content-center align-self-center">
                <p>
                  {{ $t('marketplaces-ueber-uns.strategie-txt') }}
                </p>
                <ul>
                  <li>{{ $t('marketplaces-ueber-uns.strategie-li-v1') }}</li>
                  <li>{{ $t('marketplaces-ueber-uns.strategie-li-v2') }}</li>
                  <li>{{ $t('marketplaces-ueber-uns.strategie-li-v3') }}</li>
                </ul>
                <p>
                  {{ $t('marketplaces-ueber-uns.strategie-txt-v2') }}
                </p>

                <div class="btn-std btn-std-strategie">
                  <router-link :to="{ name: 'marketplaces-startup-ventures' }"
                    ><span class="txt">{{ $t('marketplaces-ueber-uns.stratgie-btn') }}</span>
                    <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="tab-brands" role="tabpanel" aria-labelledby="tab-brands-tab">
            <div class="row justify-content-center align-self-center">
              <div class="col-lg-12  justify-content-center align-self-center offset-lg-1">
                <h3 class="text-center">{{ $t('marketplaces-ueber-uns.marken-hl') }}</h3>
                <div class="brands-overview row justify-content-center">
                  <div class="col-lg-4 col-md-4 justify-content-center border-right-line border-bottom-line">
                    <router-link :to="{ name: 'willhaben' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/willhaben.svg" alt="Willhaben" class="img-fluid" />
                    </router-link>
                  </div>
                  <div class="col-lg-4 col-md-4 justify-content-center border-bottom-line border-right-line">
                    <router-link :to="{ name: 'njuskalo' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/njuskalo.svg" alt="Njuskalo" class="img-fluid" />
                    </router-link>
                  </div>
                  <div class="col-lg-4 col-md-4 justify-content-center border-bottom-line">
                    <router-link :to="{ name: 'bolha' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/bolha.svg" alt="bolha.com" class="img-fluid" />
                    </router-link>
                  </div>
                </div>
                <div class="brands-overview row justify-content-center">
                  <div class="col-lg-4 col-md-4 justify-content-center border-right-line border-bottom-line">
                    <router-link :to="{ name: 'mojedelo' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/mojedelo.svg" alt="Mojedelo" class="img-fluid" />
                    </router-link>
                  </div>
                  <div class="col-lg-4 col-md-4 justify-content-center border-right-line border-bottom-line">
                    <router-link :to="{ name: 'njuskalo-technology' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/njuskalo-technology.png" alt="Njuskalo Technology" class="img-fluid" />
                    </router-link>
                  </div>
                  <div class="col-lg-4 col-md-4 justify-content-center border-bottom-line">
                    <router-link :to="{ name: 'autopro24' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/autopro24.png" alt="autoPro24" class="img-fluid" />
                    </router-link>
                  </div>
                </div>
                <div class="brands-overview row justify-content-center">
                  <div class="col-lg-4 col-md-4 justify-content-center border-left-line border-right-line">
                    <router-link :to="{ name: 'dimedia' }" class="brands-overview-inner">
                      <img src="@/assets/img/marken/brands/dimedia.png" alt="dimedia" class="img-fluid" />
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- -->

      <div class="row">
        <div class="col-12">
          <div class="btn-std">
            <router-link :to="{ name: 'marketplaces-uebersicht' }"
              ><span class="txt">{{ $t('General.back-to-marketplaces-overview') }}</span>
              <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Über uns | Styria Media Group',
      keywords: ['marketplaces', 'bestby', 'classifieds', 'willhaben', 'styria media group', 'styria'],
      description:
        'Wir sind der führende Anbieter von digitalen Marktplätzen in unseren Märkten und vernetzen täglich Millionen von Menschen miteinander.',
      image: require('@/assets/img/og/brands.png'),
    },
    en: {
      title: 'About us | Styria Media Group',
      keywords: ['marketplaces', 'bestby', 'classifieds', 'willhaben', 'styria media group', 'styria'],
      description: 'We are the leading provider of digital marketplaces in our markets and connect millions of people every day.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
