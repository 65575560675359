
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Über uns | Styria Media Group',
      keywords: ['marketplaces', 'bestby', 'classifieds', 'willhaben', 'styria media group', 'styria'],
      description:
        'Wir sind der führende Anbieter von digitalen Marktplätzen in unseren Märkten und vernetzen täglich Millionen von Menschen miteinander.',
      image: require('@/assets/img/og/brands.png'),
    },
    en: {
      title: 'About us | Styria Media Group',
      keywords: ['marketplaces', 'bestby', 'classifieds', 'willhaben', 'styria media group', 'styria'],
      description: 'We are the leading provider of digital marketplaces in our markets and connect millions of people every day.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
